const titleAnimation = {
  initial: {
    opacity: 0,
  },

  animate: {
    opacity: 1,

    transition: {
      type: "tween",
      duration: 1,
    },
  },
};

module.exports = {
  titleAnimation,
};
