import { motion } from "framer-motion";
import "./Socials.scss";
import { itemAnimation } from "../animations/itemAnimations";

function Socials() {
  return (
    <div id="socials">
      <section>
        {socialsArray.map(({ index, link, icon, label }) => (
          <motion.a
            key={label}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={label}
            variants={itemAnimation}
            initial="initial"
            whileInView="animate"
            custom={{ index: index, time: 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <i className={icon}></i>
          </motion.a>
        ))}
      </section>
    </div>
  );
}

const socialsArray = [
  {
    index: 1,
    link: "https://github.com/Dimajkee",
    icon: "fa-brands fa-github fa-3x",
    label: "GitHub Profile",
  },
  {
    index: 2,
    link: "https://www.instagram.com/paulpravdic/",
    icon: "fa-brands fa-instagram fa-3x",
    label: "Instagram Profile",
  },
  {
    index: 3,
    link: "https://www.linkedin.com/in/paul-pravdić-241b80313/",
    icon: "fa-brands fa-linkedin-in fa-3x",
    label: "LinkedIn Profile",
  },
  {
    index: 4,
    link: "mailto: paul.pravdic8@gmail.com",
    icon: "fa-solid fa-envelope fa-3x",
    label: "Send Email",
  },
];

export default Socials;
