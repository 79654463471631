import { motion } from "framer-motion";
import { useState } from "react";
import "./Experience.scss";

import { itemAnimation } from "../animations/itemAnimations";
import Title from "../components/Title";

function Experience() {
  return (
    <div id="experience">
      <main>
        <Title>EXPERIENCE</Title>
        <section>
          <ExpItem
            title={"Dixie Bot"}
            description={
              "I am a part of team that was creating a discord bot names `Dixie`. My job is creating discord commands and also making a maintenance page"
            }
            role={"App Development and Front-end"}
            company={"Personal project"}
            date={"June/2024 - On-going"}
            index={1}
          />
          <ExpItem
            title={"Clickpulse"}
            description={
              "My job on this project was designing and creating Front-end part. Also I helped with some parts of Back-end"
            }
            role={"App Development and Front-end"}
            company={"Personal project"}
            date={"June/2024 - On-going"}
            index={1}
          />
        </section>
      </main>
    </div>
  );
}

function ExpItem({
  title,
  description,
  techStack,
  role,
  company,
  date,
  index,
}) {
  const [toggleDescription, setToggleDescription] = useState("hide");

  function toggle() {
    setToggleDescription(toggleDescription === "hide" ? "" : "hide");
  }

  return (
    <motion.article
      variants={itemAnimation}
      initial="initial"
      whileInView="animate"
      custom={{ index: index, time: 0.2 }}
      viewport={{ once: true, amount: 0.5 }}
    >
      <div className={`title border-${toggleDescription}`}>
        <div className="content">
          <p>{title}</p>
          <h2>{role}</h2>
          <h3>{company}</h3>
          <p className="date">{date}</p>
        </div>
        <div className="more">
          <i
            className={`fas fa-chevron-right arrow-${toggleDescription}`}
            onClick={() => {
              toggle();
            }}
          ></i>
        </div>
      </div>
      <div className={`description ${toggleDescription}`}>
        <p>{description}</p>
      </div>
    </motion.article>
  );
}

export default Experience;
