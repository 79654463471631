import Banner from "./pages/Banner";
import About from "./pages/About";
import Socials from "./pages/Socials";
import Experience from "./pages/Experience";
import Education from "./pages/Education";
import Skills from "./pages/Skills";
import Footer from "./pages/Footer";
import Projects from "./pages/Projects";

function App() {
  return (
    <div className="App">
      <Banner />
      <Socials />
      <About />
      <Experience />
      <Education />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
