import { motion } from "framer-motion";
import "./About.scss";
import adnanCV from "../files/PaulPravdicCV.pdf";
import Title from "../components/Title";

import { itemAnimation } from "../animations/itemAnimations";
import { titleAnimation } from "../animations/titleAnimation";

function About() {
  return (
    <div id="about">
      <main>
        <Title>ABOUT</Title>
        <motion.section
          variants={itemAnimation}
          initial="initial"
          whileInView="animate"
          custom={{ index: 0, time: 0.2 }}
          viewport={{ once: true, amount: 0.5 }}
        >
          <p>
            My name is Paul Pravdić and I am a Freelance Web Developer
            from Kiseljak, Bosnia and Herzegovina. 
            I went to college in Mostar from where I transfered to
            different college in my home town, in Kiseljak.
            I have a lot practical expirience gained by doing personal
            projects.
          </p>
          <p>
            {" "}
            My main focus is Front-end, creating, designing and
            developing. But my abilities don't stop there since
            I had to work also on Back-end for some of the projects
            that I was a part in.
          </p>
        </motion.section>
        <a
          href={adnanCV}
          download="PaulPravdicCV"
          target="_blank"
          rel="noreferrer"
        >
          <motion.button
            variants={titleAnimation}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true, amount: 0.5 }}
          >
            DOWNLOAD CV
          </motion.button>
        </a>
      </main>
    </div>
  );
}

export default About;
