import { motion } from "framer-motion";
import "./Projects.scss";
import Title from "../components/Title";
import { itemAnimation } from "../animations/itemAnimations";

function Projects() {
  return (
    <div id="projects">
      <main>
        <Title>PROJECTS</Title>
        <section>
          <ProjectsItem
            title={"Dixie Bot (Ongoing Project)"}
            description={
              <>
              <p>
                My part is working on a bot, creating commands and building maintenance page. 
                </p>
                <a href="https://dixiebot.com" target="_blank" rel="noopener noreferrer">
                Check Dixie here.
                </a>
              </>
            }
            index={1}
          />
          <ProjectsItem
            title={"Clickpulse (Ongoing Project)"}
            description={
              <>
              <p>
              My part was designing and creating Front-end and to help with some of the Back-end. 
                </p>
                <a href="https://clickpulse.xyz" target="_blank" rel="noopener noreferrer">
                You can view Clickpulse here.
                </a>
              </>
            }
            index={2}
          />
          <ProjectsItem
            title={"Learning Advantage (Ongoing Project)"}
            description={
              "I am currently working on a web-site and mobile app for people with learning diffuculties. I have consoled social workers and psychiatrist for help with the app."
            }
            index={3}
          />
        </section>
      </main>
    </div>
  );
}

function ProjectsItem({
  title,
  description,
  liveDemoUrl,
  frontEndSourceCodeUrl,
  backEndSourceCodeUrl,
  index,
}) {
  return (
    <motion.article
      variants={itemAnimation}
      initial="initial"
      whileInView="animate"
      custom={{ index: index, time: 0.2 }}
      viewport={{ once: true, amount: 0.5 }}
    >
      <h2>{title}</h2>
      <p>{description}</p>
      <div>
        {liveDemoUrl ? (
          <>
            <h3>Preview</h3>
            <a
              rel="noopener noreferrer"
              target="_blank"
              label="Linker live demo url"
              href={liveDemoUrl}
            >
              Live Demo
              <span className="material-symbols-outlined">chevron_right</span>
            </a>
          </>
        ) : null}
        {frontEndSourceCodeUrl ? (
          <a
            rel="noopener noreferrer"
            target="_blank"
            label="Linker source code url"
            href={frontEndSourceCodeUrl}
          >
            Front-end Source Code
            <span className="material-symbols-outlined">chevron_right</span>
          </a>
        ) : null}
        {backEndSourceCodeUrl ? (
          <a
            rel="noopener noreferrer"
            target="_blank"
            label="Linker source code url"
            href={backEndSourceCodeUrl}
          >
            Back-end Source Code
            <span className="material-symbols-outlined">chevron_right</span>
          </a>
        ) : null}
      </div>
    </motion.article>
  );
}

export default Projects;
